import { useLocalization } from "@tm/localization"
import { useCallback, useState } from "react"
import { Box, styled } from "@mui/material"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { getContrastText } from "../../theme"
import { Image } from "../../generics/image"
import { Tooltip } from "../../generics/tooltip"

const SupplierBox = styled(Box, { shouldForwardProp: (prop: string) => !["selected", "showLogo"].includes(prop) })<{
    selected?: boolean
    showLogo?: boolean
    ellipses?: boolean
}>(({ theme, selected, onClick, showLogo, ellipses }) => ({
    ...(!showLogo && {
        maxHeight: 40,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        borderRadius: theme.radius?.default,
        fontFamily: theme.font.fontFamily.condensed ?? theme.font.fontFamily.secondary,
        textAlign: "center",
        padding: 2,
        ...(selected && {
            padding: "2px 4px",
            backgroundColor: theme.palette.primary.main,
            color: getContrastText(theme.palette.primary.main),
            "&:hover": {
                color: getContrastText(theme.palette.primary.main),
            },
        }),
        ...(ellipses && {
            whiteSpace: "nowrap",
            display: "block",
        }),
    }),
    ...(showLogo &&
        selected && {
            border: `5px solid ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
        }),
    ...(!!onClick && {
        cursor: "pointer",
    }),
}))

const SupplierImage = styled(Image)({
    maxWidth: 80,
    maxHeight: 32,
    display: "block",
})

type Props = {
    compactView?: boolean
}

export function Supplier({ compactView }: Props) {
    const { translateText } = useLocalization()

    const { supplier, supplierLogo, selected } = useDefaultArticleItemState((x) => x.displayArticle.supplier)
    const toggleSupplierFilter = useDefaultArticleItemState((x) => x.toggleSupplierFilter)
    const expanded = useDefaultArticleItemState((x) => x.expanded)
    const [error, setError] = useState(false)

    const handleImageError = useCallback(() => {
        setError(true)
    }, [])

    const handleClick = useCallback(() => {
        toggleSupplierFilter?.(true)
    }, [toggleSupplierFilter])

    const showLogo = !error && !!supplierLogo && expanded

    return (
        <Tooltip title={`${translateText(1048)}: ${supplier.name}`}>
            <SupplierBox onClick={handleClick} selected={selected} showLogo={showLogo} ellipses={compactView}>
                {showLogo ? <SupplierImage onError={handleImageError} src={supplierLogo} loading="lazy" /> : supplier.name}
            </SupplierBox>
        </Tooltip>
    )
}
