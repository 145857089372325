import { MouseEvent, useCallback, useMemo, useState } from "react"
import { MenuList, Popover } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useDefaultArticleItemState } from "../../ContextProviderStates/DefaultArticleItemState"
import { Watchlist } from "../../Watchlist"
import { IconButton } from "../../IconButton"
import { Icon } from "../../../../generics/Icons"
import { ActionMenuItem } from "./ActionMenuItem"
import { Tooltip } from "../../../../generics/tooltip"

export function CompactActionsContent() {
    const articleActions = useDefaultArticleItemState((x) => x.articleActions)
    const { hasWatchList, highlightWatchlistButton } = useDefaultArticleItemState((x) => x.options)
    const [moreMenuEl, setMoreMenuEl] = useState<HTMLButtonElement>()
    const { translateText } = useLocalization()

    const buttonList = useMemo(() => articleActions.filter(({ handler, area }) => area === "RIGHT_ICONS" && !!handler), [articleActions])
    const moreList = useMemo(() => articleActions.filter(({ handler, area }) => area === "RIGHT_MENU" && !!handler), [articleActions])

    const handleClickMore = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setMoreMenuEl(e.currentTarget)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleFurtherOptions)
    }, [])

    const handleCloseMore = useCallback(() => {
        setMoreMenuEl(undefined)
    }, [])

    return (
        <>
            {hasWatchList && highlightWatchlistButton && <Watchlist />}

            {(!!moreList.length || !!buttonList.length || !!hasWatchList) && (
                <>
                    <Tooltip title={translateText(13775)}>
                        <IconButton onClick={handleClickMore} hoverEffect disableRipple>
                            <Icon name="ellipsis" />
                        </IconButton>
                    </Tooltip>
                    <Popover
                        anchorEl={moreMenuEl}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        open={!!moreMenuEl}
                        onClose={handleCloseMore}
                    >
                        <MenuList variant="menu">
                            {buttonList.map((action) => (
                                <ActionMenuItem handleCloseMore={handleCloseMore} action={action} />
                            ))}
                            {hasWatchList && !highlightWatchlistButton && <Watchlist />}
                            {moreList.map((action) => (
                                <ActionMenuItem handleCloseMore={handleCloseMore} action={action} />
                            ))}
                        </MenuList>
                    </Popover>
                </>
            )}
        </>
    )
}
