import { ArticleErpInfo, ErpInformation, ErpSystemConfig, PriceType, SuccessArticleErpInfo } from "@tm/models"
import { useMemo } from "react"
import { Box, Skeleton, Stack } from "@mui/material"
import { useDefaultErpSystem, useErpConfig } from "@tm/utils"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ErpIcons, ErpIconsWrapper } from "../ErpIcons"
import { Availabilities } from "./Availabilities"
import { ExtendedDelivery } from "./ExtendedDelivery"
import { HTMLInformation } from "./HTMLInformation"
import { OtherPrices } from "./OtherPrices"
import { PriceUnit } from "./PriceUnit"
import { ErpPriceWithAdditionalInfo, Prices } from "./Prices"
import { Costs } from "./Costs"
import { useArticleItemState } from "../ContextProviderStates"
import { useWholesalerArticleItemState } from "../ContextProviderStates/WholesalerArticleItemState"

const COMPACTMODE_ALIGN_CENTER = "center"

export function DefaultArticleErpContainer() {
    const { requestErpInfo } = useDefaultArticleItemState((x) => x.article)

    if (!requestErpInfo) {
        return null
    }

    return <ErpContainer />
}

export function OePartErpContainer() {
    return <ErpContainer hideExtendedDelivery />
}

export function WholesalerErpContainer() {
    const articleErpInfos = useWholesalerArticleItemState((x) => x.article)
    const erpSystem = useErpConfig().erpSystemConfigs?.find((x) => x.isDefaultGetErpInfoSystem)
    const defaultArticleErpInfo: SuccessArticleErpInfo = {
        response: articleErpInfos as ErpInformation,
        erpSystem,
        state: "success",
        sortNumber: 0,
    }
    return <ErpContainerComponent defaultArticleErpInfo={defaultArticleErpInfo} />
}

type ErpContainerProps = {
    hideExtendedDelivery?: boolean
}

function ErpContainer(props: ErpContainerProps) {
    const articleErpInfos = useArticleItemState((x) => x.articleErpInfos)
    const isExpanded = useDefaultArticleItemState((x) => x.expanded)
    const defaultArticleErpInfo = articleErpInfos?.default
    const alternativeArticleErpInfo = articleErpInfos?.alternative

    if (!defaultArticleErpInfo && !alternativeArticleErpInfo) {
        return <Box height={32} />
    }

    if (defaultArticleErpInfo?.state === "loading" || (!defaultArticleErpInfo && alternativeArticleErpInfo?.state === "loading")) {
        return (
            <Stack direction="row" justifyContent="space-between">
                <Skeleton variant="rounded" width={100} height={32} />
                <Stack spacing="2px">
                    <Skeleton variant="rounded" width={80} height={15} />
                    <Skeleton variant="rounded" width={80} height={15} />
                </Stack>
            </Stack>
        )
    }

    // in case of oeArticles, the isExpanded flag is set to undefined, i can't find out where it set to undefined...
    // and if it is set to undefined, the extraCost are not displayed!
    return (
        <ErpContainerComponent
            {...props}
            isExpanded={isExpanded ?? true}
            defaultArticleErpInfo={defaultArticleErpInfo?.state === "success" ? defaultArticleErpInfo : undefined}
            alternativeArticleErpInfo={alternativeArticleErpInfo?.state === "success" ? alternativeArticleErpInfo : undefined}
        />
    )
}

type ErpContainerComponentProps = ErpContainerProps & {
    isExpanded?: boolean
    defaultArticleErpInfo?: SuccessArticleErpInfo
    alternativeArticleErpInfo?: SuccessArticleErpInfo
}

function ErpContainerComponent({ hideExtendedDelivery, defaultArticleErpInfo, alternativeArticleErpInfo, isExpanded }: ErpContainerComponentProps) {
    const isPriceTypeHidden = useDefaultArticleItemState((x) => x.options.isPriceTypeHidden)
    const defaultErpInfo = defaultArticleErpInfo?.response

    const mainPricesWithAdditionalInfo = useMemo(() => {
        const prices =
            defaultErpInfo?.prices
                ?.filter((x) => x.type)
                .map<ErpPriceWithAdditionalInfo>((x) => ({
                    ...x,
                    onlyShowInfo: isPriceTypeHidden(x.type),
                })) ?? []
        if (prices.length > 2 && !isExpanded) {
            // only show first 2 prices in compact view
            return prices.slice(0, 2)
        }
        return prices
    }, [defaultErpInfo?.prices, isPriceTypeHidden, isExpanded])

    const purchasePrice = useMemo(() => defaultErpInfo?.prices?.filter((price) => price.type === PriceType.Purchase)[0], [defaultErpInfo?.prices])

    const otherPrices = useMemo(() => defaultErpInfo?.prices?.filter((price) => !price.type && !!price.description) ?? [], [defaultErpInfo?.prices])

    const alignItems = isExpanded ? undefined : COMPACTMODE_ALIGN_CENTER
    return (
        <Stack gap={1} className="erpContainer">
            <Stack className="erpContainerGrid" display="grid" gridTemplateColumns="auto auto" columnGap={1} rowGap="2px" alignItems={alignItems}>
                <Stack direction="row" className="availabilityContainer">
                    <Availabilities
                        defaultArticleErpInfo={defaultArticleErpInfo}
                        alternativeArticleErpInfo={alternativeArticleErpInfo}
                        isCompact={!isExpanded}
                    />
                </Stack>
                {!!mainPricesWithAdditionalInfo.length && (
                    <Stack>
                        <Prices prices={mainPricesWithAdditionalInfo} isCompact={!isExpanded} />
                        {purchasePrice && <PriceUnit purchasePrice={purchasePrice} />}
                    </Stack>
                )}
            </Stack>
            {isExpanded && (
                <>
                    {!!otherPrices.length && (
                        <Box alignSelf="flex-end">
                            <OtherPrices prices={otherPrices} />
                        </Box>
                    )}
                    {!!defaultErpInfo?.costs?.length && <Costs costs={defaultErpInfo.costs} />}
                    {!hideExtendedDelivery && defaultErpInfo?.isTeccomRequestAvailable && <ExtendedDelivery />}

                    <ErpIconsWrapper>
                        <ErpIcons position="erpInfo" />
                    </ErpIconsWrapper>

                    {defaultErpInfo?.additionalInformationExtended && (
                        <HTMLInformation additionalInformationExtended={defaultErpInfo?.additionalInformationExtended} />
                    )}
                </>
            )}
        </Stack>
    )
}

// function getOrderedPrices(pricesToSort: ErpPrice[], priceOrder: (string | number)[][]) {
//     if (priceOrder && priceOrder[0].length > 0) {
//         return sortBy(pricesToSort, (x) => priceOrder[0].indexOf(x.type))
//     }

//     return pricesToSort
// }
