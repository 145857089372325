import { useCallback, useMemo, useState } from "react"
import { Box } from "@mui/material"
import { BonusSystemParams } from "@tm/models"
import { Image } from "../../../generics/image"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

type BonusSystemImageProps = {
    bonus: BonusSystemParams
}

export function BonusSystemImage({ bonus }: BonusSystemImageProps) {
    const [useDefaultTheme, setUseDefaultTheme] = useState(false)
    const [trySvgImage, setTrySvgImage] = useState(true)
    const [imageError, setImageError] = useState(false)
    const isCompact = useDefaultArticleItemState((x) => !x.expanded)

    const imageUrl = useMemo(() => {
        let url = bonus.imageUrl

        if (url) {
            if (useDefaultTheme) {
                url = url.replace(/(\/)\d+(\/)/, "$1-1$2")
            }

            if (trySvgImage) {
                url = `${url.substring(0, url.lastIndexOf("."))}.svg`
            }
        }

        return url
    }, [useDefaultTheme, trySvgImage, bonus.imageUrl])

    const handleImageLoadError = useCallback(() => {
        if (trySvgImage) {
            setTrySvgImage(false)
        } else if (!useDefaultTheme) {
            setTrySvgImage(true)
            setUseDefaultTheme(true)
        } else {
            setImageError(true)
        }
    }, [useDefaultTheme, trySvgImage])

    if (imageError || !imageUrl) {
        return null
    }

    return (
        <Box sx={{ marginBottom: "2px" }}>
            <Image
                width={isCompact ? "36px" : "62px"}
                height={isCompact ? "18px" : "30px"}
                src={imageUrl}
                title={bonus.name}
                onError={handleImageLoadError}
            />
        </Box>
    )
}
